// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$doctor-primary: mat-palette($mat-indigo);
$doctor-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$doctor-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$doctor-theme: mat-light-theme(
  (
    color: (
      primary: $doctor-primary,
      accent: $doctor-accent,
      warn: $doctor-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($doctor-theme);

/* You can add global styles to this file, and also import other style files */
@import '~bootstrap/scss/bootstrap-grid.scss';

@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

@import '~ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import '~ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
@import '~animate.css/animate.min.css';

html,
body {
  height: 100%;
}
body {
  margin: 0;
  // font-family: Roboto, 'Helvetica Neue', sans-serif;
  font-family: 'Poppins', sans-serif !important;
}

.ql-container {
  font-family: 'Poppins', sans-serif !important;
  font-size: 0.9rem;
  // .ql-editor {
  //   padding: 0;
  // }
}

button,
span {
  outline: none !important;
}

.page-wrapper {
  position: relative;
}

::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
}

::-webkit-scrollbar-track {
  background-color: #eeeeee;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border-radius: 4px;

  &:hover {
    background-color: darken(#cccccc, 10);
  }
}

.questionnaire-table {
  td,
  th {
    padding: 0.1rem 0.3rem;
  }
  p,
  ul {
    margin: 0 !important;
  }
  ul {
    padding-left: 1rem;
  }
}
